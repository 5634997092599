import React from "react"
import { graphql } from "gatsby"
import "../components/layout.scss"
import Layout from "../components/layout"
//import Seo from "../components/seo"
import Filter from "../components/filter"
import Helmet from "react-helmet"

const IndexPage = ({ data }) => {
  const projects = data?.allDirectusProject?.nodes
    ? data?.allDirectusProject?.nodes
    : []
  return (
    <div id="version2">
      <Layout>
        <Helmet>
          <body className="p2" />
        </Helmet>
        <Filter projects={projects} />
      </Layout>
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Projects2 {
    allDirectusProject {
      nodes {
        id
        title
        category
        video
        video_image {
          id
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
